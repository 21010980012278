import {  Button, Grid, TextField, Box, FormControl } from '@mui/material'
import {React, useState } from 'react'
import axios from 'axios'
import WordsList from './WordsList'


const initialDefaultValues = {
  name: "",
  search: "",
  text: ""
}

const SpeakCheck = () => {
  const [values, setValues] = useState(initialDefaultValues)
  const [results, setResults] = useState([])
  const [show, setShow] = useState(false)

  const onChangeHandle= (event) => {
    setValues({
        ...values,
        [event.target.name]:  event.target.value
    })

    setShow(false)
  }

  const onFormSubmit = (event) => {
    console.log('event', event);
    event.preventDefault();
  }

  const onClickHandle = () => {
    console.log('values: ',values);
    const postData = async () => {
      console.log('REACT_APP_API_URL',process.env.REACT_APP_API_URL);
      const response = await axios(process.env.REACT_APP_API_URL+'/api/text',{
      // const response = await axios('https://nsapi.taapeylabs.com/api/text',{
        method: 'POST',
        data: values,
        header: {
          'Content-Type': 'application/json'
      }}
      )
      console.log('response:',response);
      setResults(response.data.result)
      return response;
    }
    postData()
    setShow(true)
  }

  return (
    <div>
      <Box sx={{flexGrow: 1}}>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
        <Grid item xs={1} ></Grid>
        <Grid item container xs={8} direction="column"  spacing={1} >
          <Grid item>
            <TextField
            id="name"
            name="name"
            label="Name"
            variant="outlined"
            value={values.name}
            onChange={onChangeHandle}
            autoComplete="off"
            sx={{width: 200}}
            />
          </Grid>
          <Grid item>
          <FormControl fullWidth>
          {/* <TextareaAutosize */}
          <TextField
          id="text"
          name="text"
          maxRows={10}
          multiline
          variant="outlined"
          value={values.text}
          label="Complete Text"
          placeholder="Complete Text"
          onChange={onChangeHandle}
          onFocus={() => {setShow(false)}}
          // style={{width: 700, height: 300}}
        /></FormControl>

          <Grid item sx={{pt: 1}}>
          <TextField
            id="search"
            name="search"
            label="Search Words"
            variant="outlined"
            value={values.search}
            onChange={onChangeHandle}
            autoComplete="off"
            sx={{width: 400}}
          />
          <Button
            sx={{m:1, width: 150}}
            variant="contained"
            color="primary"
            onClick={onClickHandle}
            >Search
          </Button>
          </Grid>
        </Grid>
        </Grid>

        <Grid item xs={2}>
        {show ? <WordsList results={results}/> : '' }
        </Grid>
        <Grid item xs={1} ></Grid>
        </Grid>
      </form>
      </Box>
    </div>
  )
}

export default SpeakCheck;