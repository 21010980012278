export const WordTabHead = [
  {
    id: 'word',
    numeric: false,
    disablePadding: true,
    label: 'Word',
  },
  {
    id: 'count',
    numeric: false,
    disablePadding: true,
    label: 'Count',
  },
]