import Navbar from './component/Navbar';
import {ThemeProvider , createTheme} from '@mui/material/styles'
import SpeakCheck from './component/SpeakCheck';


const theme = createTheme({
  palette: {
    type: 'light'
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App">

     <Navbar/>
     <SpeakCheck/>
    </div>
    </ThemeProvider>
  );
}

export default App;
