
import React from 'react'
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useTable from '../hooks/useTable' 

const WordsList = ({results}) => {

const { TblContainer, TblHead } = useTable({results});

   return (
     <div>
        <Paper variant="elevation" sx={{justifyContent: 'flex-start', p: 0, pt: 0.1, pb: 0.1 }}>

          <TblContainer>
            <TblHead />
            <TableBody>
            {
              results.map((item) => {
              return (
               <TableRow key={item.word} sx={{height:'20'}}>
                 <TableCell>{item.word}</TableCell>
                 <TableCell>{item.count}</TableCell>
               </TableRow>
              )
              })
            }
        </TableBody>
      </TblContainer>
      {/* <TblPagination /> */}
      </Paper>
     </div>
  )
}

export default WordsList;
