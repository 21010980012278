import React from 'react'
import {Grid, Typography } from '@mui/material'

const Navbar = () => {
  return (
    <div>
      <Grid container spacing={1} justifyContent="center">
      <Typography variant="h2">NimSpeak App</Typography>
      <Grid item container direction="column" sm={12}  >

      </Grid>
      <Grid item container direction="row" sm={8} >
<div>

</div>
      </Grid>

      </Grid>
    </div>
  )
}

export default Navbar
